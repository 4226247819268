
    import { Component, Prop, Vue } from "vue-property-decorator";
    import RoutesEnum from "../../../router/routesEnum";
    import FormatMoney from "@/app/common/components/FormatMoney.vue";
    import { InvoiceSummaryModel } from "@/apiManager/invoice/invoiceData";

    @Component
    export default class OutstandingInvoiceList extends Vue
    {

        private outstandingInvoiceList: InvoiceSummaryModel[] = [];

        async mounted()
        {
            try
            {
                this.outstandingInvoiceList = await this.$apiManager.invoice.listAsync();
            }
            catch(ex)
            {
                this.$router.replace({name: RoutesEnum.Package});
            }
        }

        onClickOutstandingInvoiceItem(p_File: string)
        {
            this.$router.push({ name: RoutesEnum.OutstandingInvoiceDetail, params: { file: p_File } });
        }

        onClickReturnHome()
        {
            this.$router.push({ name: RoutesEnum.Package });
        }

        get components()
        {
            let components =
                {
                    "format-money": FormatMoney
                };
            return components;
        }

    }
